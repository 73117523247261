const teamEn = {
  save: "Save",
  cancel: "Cancel",
  english: "English",
  french: "French",
  dutch: "Dutch",
  yes: "Yes",
  no: "No"
};
export default teamEn;
