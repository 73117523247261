import axios from "axios";
import { TTP_API_URL } from "../config";
import moment from "moment";

export const getCollaborators = ({ token, organizationId, year }) => {
  const requestUrl = `${TTP_API_URL}/team/budget/collaborators`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      year,
    },
  });
};

export const getCollaborator = (id, organizationId, token, uid = null) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organizationId,
      uid: uid,
      id,
    },
  });
};

export const getCollaboratorsTimeSheetNotification = (
  organizationId,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/gd_notifications`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
    },
  });
};

export const getManagerNotification = (
  uid,
  organizationId,
  token,
  types,
  start,
  limit
) => {
  const requestUrl = `${TTP_API_URL}/team/manager_notifications`;
  let parametres = {
    types: types,
    start,
    limit,
  };
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      year: moment().year(),
      uid,
      params: JSON.stringify(parametres),
      organizationId,
    },
  });
};

export const getPersonnalDashboard = (
  organizationId,
  token,
  filter,
  collaboratorId
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/personal-dashboard`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      collaboratorId,
      role: filter.role,
      entityClientId: filter.clients,
      sortBy: filter.sortBy,
      keyWord: filter.keyWord,
      year: filter.year,
      isEntity: filter.isEntity,
      deletedStatus: filter.deletedStatus,
      clientCRMGroup: JSON.stringify(filter.clientCRMGroup),
      clientCRMTypes: JSON.stringify(filter.clientCRMTypes),
    },
  });
};

export const getCollaboratorFinancial = (
  id,
  organization,
  token,
  year,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/financial`;
  return axios.get(requestUrl, {
    params: {
      organization: organization,
      organization_id: organization,
      access_token: token,
      collaborator: id,
      sortBy: filter.sortBy,
      role: filter.role,
      attribution: filter.attribution,
      clientCRMGroup: JSON.stringify(filter.clientCRMGroup),
      clientCRMTypes: JSON.stringify(filter.clientCRMTypes),
      deletedStatus: filter.deletedStatus,
      year,
      searchWord: filter.keyWord,
    },
  });
};
