import React, { useState } from "react";
import { useQuery } from "react-query";

import _ from "../../../i18n";
import {
  FINANCIAL_COLLABORATOR_SORT_TYPES,
  COLLABORATOR_ATTRIBUTION_FILTER,
  COLLABORATOR_ROLE_ATTRIBUT,
  CLIENT_CRM_TYPE_FILTER,
  DELETED_STATUS,
} from "../../../config";
import { getGroupsDescription } from "../../../api";

import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";

const FinancialCollaboratorFilter = ({
  nbResult,
  filter,
  updateFilter,
  filterVisible,
  token,
  updateFiltersCount,
  organization,
}) => {
  const [groups, setGroups] = useState([]);

  let clientGroupsDescriptionQuery = useQuery(
    ["clientGroupsDescriptionQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getGroupsDescription(organization, token);
          let data = response.data.data.map((t) => {
            return {
              value: t.group,
              label: t.group,
            };
          });
          setGroups(data);
        } catch (e) {
          setGroups([]);
          return null;
        }
      }
    }
  );

  const renderCustomAddOn = () => {
    return (
      <div className="result_label">
        {_("folderCount")} : <span>{nbResult}</span>
      </div>
    );
  };

  let filters = [
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("sortBy"),
        options: FINANCIAL_COLLABORATOR_SORT_TYPES,
        values: filter.sortBy,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.sortBy;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, sortBy: value });
          }
        },
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("Type Client (CRM)"),
        options: CLIENT_CRM_TYPE_FILTER,
        values: filter.clientCRMTypes,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.clientCRMTypes;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, clientCRMTypes: value });
          }
        },
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientState"),
        options: DELETED_STATUS,
        values: filter.deletedStatus,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.deletedStatus;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, deletedStatus: value });
          }
        },
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        placeholder: _("Dossiers où il"),
        options: COLLABORATOR_ATTRIBUTION_FILTER,
        values: filter.attribution,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.attribution;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, attribution: value });
          }
        },
      },
    },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("Groupe Client (CRM)"),
        options: groups,
        values: filter.clientCRMGroup,
        action: (value) => {
          if (value.length == 0) {
            let cleanFilter = { ...filter };
            delete cleanFilter.clientCRMGroup;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, clientCRMGroup: value });
          }
        },
      },
    },
  ];
  filter.attribution === "BUDGETED_FOLDERS" &&
    filters.push({
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        placeholder: _("Collaborateur Role"),
        options: COLLABORATOR_ROLE_ATTRIBUT,
        values: filter.role,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.role;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, role: value });
          }
        },
      },
    });

  let countFilter = 0;
  filters.forEach((filter) => {
    if (
      filter &&
      filter.options.values &&
      (filter.options.values > 0 || filter.options.values.length > 0)
    ) {
      countFilter++;
    }
  });
  updateFiltersCount(countFilter);

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          filterFormIsVisible={filterVisible}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default FinancialCollaboratorFilter;
