import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Badge } from "antd";
import moment from "moment";
import { getManagerNotification } from "../../api";
import { setManagerNotification } from "../../store";
import classnames from "classnames";
import {
  SynchroDownIcon,
  SynchroRuningIcon,
  IconChevronDown,
  MaximizeIcon,
  IconPlus,
  CircleIcon,
  AlertIcon,
  BellIcon,
} from "../common/Icons";
import _ from "../../i18n";
import { NOTIFICATION_TABS } from "../../config";
import {
  renderNotificationManagerMessage,
  renderNotificationCollaboratorMessage,
} from "../../utils";
import { TiArrowSortedDown } from "react-icons/ti";
import { Tooltip } from "antd";

const SubMenu = (menu) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.params);
  const [interval, setInterval] = useState({ start: 10, limit: 10 });
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS[0]);

  const preferences = auth.user
    ? auth.user.userOrganizations.find(
        (organization) => organization.id === auth.navCommunity.id
      ).authorisation.preferences
    : {};

  const handleFetchNotifications = async () => {
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        auth.loggedAs === "COLLABORATOR"
          ? ["correction_card_validated"]
          : [
              "correction_card_affected",
              "correction_card_removed",
              "correction_card_returned",
              "correction_card_validated",
            ],
        interval.start,
        interval.limit
      );
      if (managerNotificationQuery) {
        managerNotificationQuery.then((result) => {
          const data = [...managerNotification.data, ...result.data.data.data];
          dispatch(
            setManagerNotification({
              nbrResult: managerNotification.nbrResult,
              data,
            })
          );
          setInterval({
            ...interval,
            start: interval.start + 10,
          });
        });
      }
    } catch (e) {
      return null;
    }
  };

  const { synchronizationList, managerNotification, loggedAs } = auth;
  return (
    <nav id="subMenu" className={`sub-menu`}>
      <ul className="menu">
        <>
          {menu &&
            menu.menu &&
            menu.menu.map((i) => (
              <>
                <li>
                  {!i.submenu ? (
                    <NavLink
                      to={i.url}
                      activeClassName="active"
                      className="menu__link"
                    >
                      <img src={i.iconUrl} />

                      <span className="title">{_(i.title)}</span>
                    </NavLink>
                  ) : (
                    <div className={"settings-menu"} style={{ width: "175px" }}>
                      <div className={"settings-menu-drop-down"}>
                        <li>
                          <img src={i.iconUrl} />
                          <span style={{ marginRight: "" }}>{_(i.title)}</span>
                          <IconChevronDown width={15} height={15} />
                        </li>
                      </div>

                      <div className={"settings-list"}>
                        {i.submenu &&
                          i.submenu.map((subMenu) => (
                            <NavLink to={subMenu.url}>
                              {_(subMenu.title)}
                            </NavLink>
                          ))}
                      </div>
                    </div>
                  )}
                </li>
              </>
            ))}
        </>
      </ul>
      {(loggedAs === "COLLABORATOR" ||
        (preferences &&
          preferences.activateNotifications &&
          loggedAs === "MANAGER")) && (
        <div className={"notifications"}>
          <BellIcon fill="#c7e8fe" size="20" className="notification_badge" />
          <div className={"notifications_list"} style={{ right: "36px" }}>
            {managerNotification && managerNotification.data.length > 0 ? (
              <Fragment>
                <div className="notif_header">
                  <div className="tabs">
                    {NOTIFICATION_TABS.map((tab) => (
                      <div
                        className={`${
                          activeTab.tab === tab.tab ? "tab_active" : ""
                        } tab`}
                        onClick={() => setActiveTab(tab)}
                      >
                        {_(tab.tab)}
                      </div>
                    ))}
                  </div>
                  <Link to={`/${language}/notifications`}>
                    <div className="action">
                      <MaximizeIcon />
                    </div>
                  </Link>
                </div>
                <div className={"list"}>
                  {managerNotification &&
                  managerNotification.data.filter(
                    (notific) =>
                      activeTab.tab === "allNotifications" ||
                      activeTab.actions.includes(notific.data.action)
                  ).length > 0 ? (
                    managerNotification &&
                    managerNotification.data
                      .filter(
                        (notific) =>
                          activeTab.tab === "allNotifications" ||
                          activeTab.actions.includes(notific.data.action)
                      )
                      .map((notif) => (
                        <Link
                          to={NOTIFICATION_TABS.filter(
                            (tab) =>
                              tab.actions &&
                              tab.actions.includes(notif.data.action)
                          )[0]
                            .redirect.replace(":language", language)
                            .replace(":id", notif.collaborator)}
                        >
                          <div className="notif_frame">
                            <div className="notif_frame_header">
                              <div>{_(notif.data.action)}</div>
                              <div>
                                {moment(notif.createdAt.date).format(
                                  "DD-MM-Y"
                                ) === moment().format("DD-MM-Y")
                                  ? moment(notif.createdAt.date)
                                      .locale(language)
                                      .fromNow()
                                  : moment(notif.createdAt.date).format(
                                      "DD-MM-Y"
                                    )}
                              </div>
                            </div>
                            <div className="notif_frame_body">
                              {loggedAs === "COLLABORATOR"
                                ? renderNotificationCollaboratorMessage(
                                    notif.data.action,
                                    notif.data
                                  )
                                : renderNotificationManagerMessage(
                                    notif.data.action,
                                    notif.data
                                  )}
                              <CircleIcon
                                fill={
                                  notif.status === "PENDING" ? "#FF636E" : ""
                                }
                              />
                            </div>
                          </div>
                        </Link>
                      ))
                  ) : (
                    <div className="no_data_notif min" style={{ width: 350 }}>
                      <div className="title">{_("noNotificationsFound")}</div>
                    </div>
                  )}
                  {managerNotification &&
                    managerNotification.nbrResult >
                      managerNotification.data.length && (
                      <div
                        className="notif_frame notif_action"
                        onClick={() => handleFetchNotifications()}
                      >
                        <div>
                          <IconPlus />
                        </div>
                      </div>
                    )}
                </div>
              </Fragment>
            ) : (
              <div className="no_data_notif min">
                <div className="title">{_("noNotificationsFound")}</div>
              </div>
            )}
          </div>
        </div>
      )}
      {loggedAs === "MANAGER" && (
        <div className={"notifications"}>
          <Badge
            count={
              synchronizationList &&
              synchronizationList.listeCollaboarators &&
              synchronizationList.synchro_is_runing &&
              synchronizationList.listeCollaboarators.length > 0
                ? synchronizationList.listeCollaboarators.length
                : 0
            }
            className={classnames(
              "notification_badge",
              synchronizationList &&
                synchronizationList.listeCollaboarators &&
                synchronizationList.listeCollaboarators.length > 0
                ? "notifications_without_header"
                : "",
              synchronizationList &&
                !synchronizationList.synchro_is_runing &&
                "synchronization_alert"
            )}
            style={{
              transform: "translate(116%, 85%)",
              minWidth: "24px",
              height: "14px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            {synchronizationList &&
              (synchronizationList.synchro_is_runing ? (
                <SynchroRuningIcon />
              ) : (
                <SynchroDownIcon />
              ))}
          </Badge>

          {synchronizationList && synchronizationList.synchro_is_runing && (
            <div className={"notifications_list"}>
              <div className={"list"}>
                {synchronizationList.listeCollaboarators &&
                synchronizationList.listeCollaboarators.length > 0 ? (
                  synchronizationList.listeCollaboarators.map((notif) => (
                    <div className="notif_line">
                      <div>{notif.name}</div>
                      {notif.state === "NOT_CONFIGURED" ? (
                        <Fragment>
                          <Tooltip
                            key="tool-2"
                            placement="top"
                            title={_("regimeNotConfigurated")}
                            mouseLeaveDelay={0}
                          >
                            <div className="tag">
                              <AlertIcon fill="#FF636E" className="red" />
                            </div>
                          </Tooltip>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="tag">
                            <TiArrowSortedDown />{" "}
                            <span>{`${parseFloat(
                              parseFloat(notif.ecart).toFixed(2)
                            )}H`}</span>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  ))
                ) : (
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    {" "}
                    Aucune notification n'est disponible{" "}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      ></div>
    </nav>
  );
};

export default SubMenu;
