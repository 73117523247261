import axios from "axios";

import { TTP_API_URL } from "../config";

export const getCapacityPlaningProduction = (
  organizationId,
  token,
  analyseDate,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/capacity/production`;
  let params = {
    access_token: token,
    organization_id: organizationId,
    organization: organizationId,
    date: analyseDate,
    searchWord: filter.keyWord,
    collaboratorState: JSON.stringify(filter.collaboratorState),
    deletedStatus: filter.deletedStatus,
    zone: filter.zone,
  };

  params.limit = filter.pageSize || 10;
  params.start = !filter.paginationPage
    ? 0
    : (filter.paginationPage - 1) * filter.pageSize;
  return axios.get(requestUrl, {
    params,
  });
};

export const getCollaboratorContribution = (
  id,
  organizationId,
  year,
  token,
  categories,
  period,
  start,
  end,
  keyWord,
  code
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/production`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id,
      year,
      organization_id: organizationId,
      organization: organizationId,
      categories: JSON.stringify(categories),
      period,
      start,
      end,
      keyWord,
      code,
    },
  });
};

export const getCollaboratorContributionByCategory = (
  id,
  organizationId,
  year,
  token,
  start,
  end
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/production-category`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id,
      year,
      organization_id: organizationId,
      organization: organizationId,
      start,
      end,
    },
  });
};

export const getOrganizationCollaborators = (
  organizationId,
  year,
  token,
  zones,
  searchWord
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators`;

  let filter = [];
  if (searchWord && searchWord.length) {
    filter = [
      {
        property: "name",
        value: `%` + searchWord + `%`,
        operator: "like",
      },
    ];
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      years: [year],
      zones,
      filter: JSON.stringify(filter),
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getCollaboratorAvatars = (organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/avatars`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const getCollaboratorsGlobalReporting = (
  organizationId,
  token,
  fliter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/globalReporting`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      searchWord: fliter.keyWord,
      year: fliter.year,
      isEntity: fliter.isEntity,
      deletedStatus: fliter.deletedStatus,
      clientCRMGroup: JSON.stringify(fliter.clientCRMGroup),
      clientCRMTypes: JSON.stringify(fliter.clientCRMTypes),
      collaborators: fliter.collaborators,
    },
  });
};
