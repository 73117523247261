import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import _ from "../../../i18n";

import { getCollaboratorFinancial } from "../../../api";

import { renderDurationDisplay } from "../../../utils";
import FinancialCollaboratorFilter from "./FinancialCollaboratorFilter";

import "../CollaboratorSpace.local.scss";
import "./FinancialCollaborator.local.scss";

const FinancialCollaborator = ({ id, year, token, organization }) => {
  //Header
  const [filtersCount, setFiltersCount] = useState(0);
  const [filter, setFilter] = useState({
    clientCRMTypes: ["CLIENT"],
    deletedStatus: "NOT_DELETED",
  });

  //Body
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: "client",
      label: _("client"),
      widthPercentage: 18,
    },
    {
      id: "budget",
      label: _("budget"),
      widthPercentage: 10,
    },
    {
      id: "real",
      label: _("presta-ts"),
      widthPercentage: 10,
    },
    {
      id: "caAdjusted",
      label: _("collaboratorCA"),
      widthPercentage: 25,
    },
    {
      id: "delta",
      label: _("delta"),
      widthPercentage: 10,
    },
    {
      id: "benchmarkEffectif",
      label: _("effectifBenshmark"),
      widthPercentage: 14,
    },
    {
      id: "benchmarkBudgetaire",
      label: _("budgetaireBenshmark"),
      widthPercentage: 14,
    },
  ]);

  let collaboratorFinancialQuery = useQuery(
    ["collaboratorFinancialQuery", filter],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorFinancial(
            id,
            organization,
            token,
            year,
            filter
          );
          setData(response.data.data);
        } catch (e) {
          setData([]);
          return null;
        }
      }
    }
  );

  const renderCelluleContent = (key, item) => {
    switch (key) {
      case "client":
        return item.client;
      case "budget":
        return item.clientBudget ? (
          <div className="composed_cellule">
            <div>{renderDurationDisplay(item.collabBudget * 60)}</div>
            <div>
              <span>{_("total")}: </span>
              <span>{renderDurationDisplay(item.clientBudget * 60)}</span>
            </div>
          </div>
        ) : (
          "0h"
        );
      case "real":
        return item.collabReal ? (
          <div className="composed_cellule">
            <div>{renderDurationDisplay(item.collabReal * 60)}</div>
            <div>
              <span>{_("total")}: </span>
              <span>{renderDurationDisplay(item.clientReal * 60)}</span>
            </div>
          </div>
        ) : (
          "0h"
        );
      case "caAdjusted":
        return (
          <div className="composed_cellule">
            <div>{parseFloat(item.collabCaAdjusted).toFixed(2)}</div>
            <div>
              <div>
                <span>{_("tauxEffectif")}: </span>
                <span>{item.tauxEffectif}€/heure</span>
              </div>
              <div>
                <span>{_("tauxBudgetaire")}: </span>
                <span>{item.tauxBudgetaire}€/heure</span>
              </div>
            </div>
          </div>
        );
      case "delta":
        let delta =
          item.collabCaReal > 0
            ? parseInt(
                parseFloat(
                  (item.collabCaAdjusted - item.collabCaReal) /
                    item.collabCaReal
                ).toFixed(2) * 100
              )
            : 0;
        return `${delta}%`;
      case "benchmarkEffectif":
        return (
          <div>
            <div>{item.benchmarkEffectif}</div>
            <div></div>
          </div>
        );
      case "benchmarkBudgetaire":
        return (
          <div>
            <div>{item.benchmarkBudgetaire}</div>
            <div></div>
          </div>
        );
      default:
        return;
    }
  };

  const renderFinancialTable = () => {
    let headerCellules = columns.map((column) => (
      <div style={{ width: `${column.widthPercentage}%` }}>{column.label}</div>
    ));
    let bodyCellules =
      data.length > 0 &&
      data.map((item) => {
        return (
          <div
            className={`${item.client === "TOTAL" ? "total_line" : ""} line`}
          >
            {columns.map((column) => {
              return (
                <div
                  className="cellule"
                  style={{ width: `${column.widthPercentage}%` }}
                >
                  {renderCelluleContent(column.id, item)}
                </div>
              );
            })}
          </div>
        );
      });
    return (
      <div className="financial_table">
        <div className="table_header">{headerCellules}</div>
        <div className="table_body">{bodyCellules}</div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="production-frame">
        <FinancialCollaboratorFilter
          organization={organization}
          token={token}
          filter={filter}
          nbResult={data.length}
          updateFilter={setFilter}
          updateFiltersCount={setFiltersCount}
          fetching={collaboratorFinancialQuery.isFetching}
        />
        {collaboratorFinancialQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : data.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("noDataFound")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          renderFinancialTable()
        )}
      </div>
    </Fragment>
  );
};

export default FinancialCollaborator;
