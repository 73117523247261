import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import _ from "../../i18n";
import {
  COLLABORATOR_ROLE_FILTER,
  CLIENT_GROUP_CRM_FILTER,
  SORT_TYPES,
  CLIENT_CRM_TYPE_FILTER,
  BUDGET_STATUS_FILTER,
  IS_ENTITY_STATES,
  DELETED_STATUS,
} from "../../config";
import { useOnClickOutside, renderTeamYears } from "../../utils";
import { getClientsSuggestions, getCollaboratorsSuggestions } from "../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../common/Filter/TTPFilterHorizontalBar";

const ClientsFilter = ({
  filter,
  nbResult,
  updateFilter,
  loggedAs,
  spaceAs,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity } = useSelector((state) => state.auth);

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);

  let clientsdQuery = useQuery(
    ["getClients", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getClientsSuggestions(
            token,
            filter.clientCRMGroup,
            navCommunity.id,
            "isEntity",
            filter.year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleEntityChange = (items) => {
    updateFilter({ ...filter, clients: items });
  };

  const handleSetIsEntityState = (state) => {
    updateFilter({ ...filter, isEntity: state });
  };

  const handleSetDeletedStatus = (status) => {
    updateFilter({ ...filter, deletedStatus: status });
  };

  const handleTypeCRMClientChange = (items) => {
    updateFilter({ ...filter, clientCRMTypes: items });
  };

  const handleGroupCRMClientChange = (items) => {
    updateFilter({ ...filter, clientCRMGroup: items });
  };

  const handleSortByClientChange = (items) => {
    updateFilter({ ...filter, sortBy: items });
  };

  const handleCollaboratorsChange = (items) => {
    updateFilter({ ...filter, collaborators: items });
  };

  const handleCollaboratorRoleChange = (item) => {
    updateFilter({ ...filter, role: item });
  };

  const handleBudgetStatusChange = (item) => {
    updateFilter({ ...filter, budgetStatus: item });
  };

  // useEffect(()
  //  => {
  //   if (spaceAs === "COLLABORATOR" || loggedAs === "COLLABORATOR") {
  //     if (collaborator) {
  //       handleCollaboratorsChange(collaborator.id);
  //       handleCollaboratorRoleChange(["SE"]);
  //     }
  //   }
  // }, [spaceAs.loggedAs, collaborator]);
  // handleCollaboratorsChange([170]);
  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        values: filter.clients,
        placeholder: _("clientsAttachedTo"),
        action: handleEntityChange,
        options: clientOptions,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientType"),
        options: IS_ENTITY_STATES,
        values: filter.isEntity,
        action: handleSetIsEntityState,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientState"),
        options: DELETED_STATUS,
        values: filter.deletedStatus,
        action: handleSetDeletedStatus,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientsType"),
        options: CLIENT_CRM_TYPE_FILTER,
        values: filter.clientCRMTypes,
        action: handleTypeCRMClientChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientsGroup"),
        options: CLIENT_GROUP_CRM_FILTER,
        values: filter.clientCRMGroup,
        action: handleGroupCRMClientChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("Trier par"),
        options: SORT_TYPES,
        values: filter.sortBy,
        action: handleSortByClientChange,
      },
    },
    // {
    //   type: "SELECT",
    //   options: {
    //     isSimple: true,
    //     isClearable: true,
    //     placeholder: _("budgetStatus"),
    //     options: BUDGET_STATUS_FILTER,
    //     values: filter.budgetStatus,
    //     action: handleBudgetStatusChange,
    //   },
    // },

    loggedAs === "MANAGER" &&
      spaceAs === "MANAGER" && {
        type: "SELECT",
        options: {
          isSimple: false,
          isMulti: true,
          isClearable: true,
          placeholder: _("collaborator"),
          options: collaboratorOptions,
          values: filter.collaborators,
          action: handleCollaboratorsChange,
        },
      },
    filter.collaborators.length > 0 && {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("folderAs"),
        options: COLLABORATOR_ROLE_FILTER,
        values: filter.role,
        action: handleCollaboratorRoleChange,
      },
    },
  ];
  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          searchBox
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default ClientsFilter;
