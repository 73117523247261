import React, { useState, useRef, Fragment } from "react";
import _ from "../../../../i18n";

import { useOnClickOutside } from "../../../../utils";
import {
  saveReason,
  deleteReason,
  saveAnomaly,
  deleteAnomaly,
} from "../../../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import { ModalConfirm } from "tamtam-components";
import {
  IconTrash,
  CheckIcon,
  IconPencil,
  IconChevronDown,
} from "../../../common/Icons";

import "./EncodingProgressionContent.local.scss";

const AddNewCorrectionCardFrame = ({
  organization,
  token,
  reasons,
  anomalies,
  onCancel,
  onConfirm,
  updateReasons,
  updateAnomalies,
}) => {
  const refAnomalies = useRef();
  useOnClickOutside(refAnomalies, () => setAnomaliesDivIsVisible(false));
  const [isDeleteReasonModalOpen, setIsDeleteReasonModalOpen] = useState(false);

  const [anomaliesDivIsVisible, setAnomaliesDivIsVisible] = useState(false);
  const [selectedAnomaly, setSelectedAnomaly] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedCategoryReason, setSelectedCategoryReason] = useState(null);
  const [selectedSuperCategoryReason, setSelectedSuperCategoryReason] =
    useState(null);
  const [newReason, setNewReason] = useState(null);
  const [newAnomaly, setNewAnomaly] = useState(null);
  const [newCategoryReason, setNewCategoryReason] = useState(null);
  const [newSuperCategoryReason, setNewSuperCategoryReason] = useState(null);

  const handleSaveReason = async (type, parent, label) => {
    try {
      const response = await saveReason({
        id: null,
        token,
        organization,
        type: type,
        parent: parent,
        label: label,
      });
      let newReasons = [...reasons];
      let reasonResponse = response.data.data;
      if (type === "SUPER") {
        newReasons.unshift(reasonResponse);
        setNewSuperCategoryReason(null);
      } else if (type === "CATEGORY") {
        setNewCategoryReason(null);
        newReasons = reasons.map((superCategory) => {
          if (superCategory.id === parent) {
            let newCategories = superCategory.items
              ? [...superCategory.items]
              : [];
            newCategories.unshift(reasonResponse);
            return { ...superCategory, items: newCategories };
          } else {
            return superCategory;
          }
        });
      } else {
        setNewReason(null);
        let clonedReason = [...reasons];
        newReasons = clonedReason.map((superCategory) => {
          let newCategories =
            superCategory.items &&
            superCategory.items.map((category) => {
              if (category.id === parent) {
                let newReasons = category.items ? [...category.items] : [];
                newReasons.unshift(reasonResponse);
                return { ...category, items: newReasons };
              } else {
                return category;
              }
            });
          return { ...superCategory, items: newCategories };
        });
      }
      updateReasons(newReasons);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleSaveAnomaly = async (label) => {
    try {
      const response = await saveAnomaly({
        id: null,
        token,
        organization,
        label: label,
      });
      let newAnomalies = [...anomalies];
      let anomalyResponse = response.data.data;
      newAnomalies.unshift(anomalyResponse);
      setSelectedAnomaly(anomalyResponse);
      setNewAnomaly(null);
      updateAnomalies(newAnomalies);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleDeleteReason = async ({ id, type }) => {
    try {
      const response = await deleteReason({
        id: id,
        token,
        organization,
        type: type,
      });
      let newReasons = [];
      let reasonResponse = response.data.data.id;
      if (type === "SUPER") {
        newReasons = reasons.filter((reason) => reason.id !== reasonResponse);
      } else if (type === "CATEGORY") {
        newReasons = reasons.map((superCategory) => {
          let newCategories = [...superCategory.items].filter(
            (category) => category.id !== reasonResponse
          );
          return { ...superCategory, items: newCategories };
        });
      } else {
        let clonedReason = [...reasons];
        newReasons = clonedReason.map((superCategory) => {
          let newCategories =
            superCategory.items &&
            superCategory.items.map((category) => {
              let newReasonLocals = category.items
                ? category.items.filter((reason) => {
                    return reason.id !== reasonResponse;
                  })
                : [];
              return { ...category, items: newReasonLocals };
            });
          return { ...superCategory, items: newCategories };
        });
      }
      updateReasons(newReasons);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleDeleteAnomaly = async ({ id }) => {
    try {
      const response = await deleteAnomaly({
        id: id,
        token,
        organization,
      });
      let newAnomalies = [];
      let anomalyResponse = response.data.data.id;
      newAnomalies = anomalies.filter(
        (anomaly) => anomaly.id !== anomalyResponse
      );
      updateAnomalies(newAnomalies);
    } catch (e) {
      return null;
    }
  };

  return (
    <div className="pre-form">
      <div className="instruction">{_("selectTheAnomaly")} * : </div>
      <div
        ref={refAnomalies}
        className={
          "rectangular_select" +
          " " +
          (anomaliesDivIsVisible ? "rectangular_select_opened" : "")
        }
      >
        <div
          className={"rectangular_select_header"}
          onClick={() => {
            setNewAnomaly(null);
            setAnomaliesDivIsVisible(!anomaliesDivIsVisible);
          }}
          style={{ width: 300 }}
        >
          {selectedAnomaly ? (
            <div>{selectedAnomaly.label}</div>
          ) : (
            <span>{_("select")}...</span>
          )}
          <TiArrowSortedDown />
        </div>
        {anomaliesDivIsVisible && (
          <div className={"rectangular_select_body"} style={{ width: 300 }}>
            <div className="input_form">
              <input
                value={newAnomaly ? newAnomaly : ""}
                placeholder={_("addNewAnomaly")}
                onChange={(event) => setNewAnomaly(event.target.value)}
              />
              {newAnomaly && (
                <div className="action-side">
                  <div
                    className="arrow"
                    onClick={() => {
                      handleSaveAnomaly(newAnomaly);
                    }}
                  >
                    <CheckIcon width={10} height="8" fill="#ffffff" />
                  </div>
                </div>
              )}
            </div>
            <div className={"rectangular_select_body_options"}>
              {anomalies.map((item, index) => (
                <div
                  key={index}
                  className={"rectangular_select_body_option input_form"}
                  onClick={() => {
                    setSelectedAnomaly(item);
                    setAnomaliesDivIsVisible(false);
                  }}
                >
                  {item.label}
                  <div
                    className="action-side"
                    style={{ background: "#FFFFFF" }}
                  >
                    <div
                      className="arrow"
                      onClick={(e) => {
                        handleDeleteAnomaly(item);
                        if (selectedAnomaly && item.id === selectedAnomaly.id) {
                          setSelectedAnomaly(null);
                        }
                        e.stopPropagation();
                      }}
                    >
                      <IconTrash size={11} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="instruction">{_("selectTheReason")} * :</div>
      <div className="grid_card">
        <div className="card card_large form_card">
          <input
            value={newSuperCategoryReason ? newSuperCategoryReason : ""}
            placeholder="add new super category..."
            onChange={(event) => setNewSuperCategoryReason(event.target.value)}
          />
          {newSuperCategoryReason && (
            <div className="action-side">
              <div
                className="arrow"
                onClick={() => {
                  handleSaveReason("SUPER", null, newSuperCategoryReason);
                }}
              >
                <CheckIcon width={10} height="8" />
              </div>
            </div>
          )}
        </div>
        {reasons.map((superCat) => (
          <Fragment>
            <div
              className={
                "card card_large" +
                " " +
                (selectedSuperCategoryReason &&
                selectedSuperCategoryReason.id === superCat.id
                  ? "card_large_open"
                  : "")
              }
            >
              <div>{superCat.label}</div>
              <div className="action-side">
                <div className="actions">
                  <div
                    className="action"
                    onClick={() =>
                      setIsDeleteReasonModalOpen({
                        type: "SUPER",
                        id: superCat.id,
                      })
                    }
                  >
                    <IconTrash size={12} />
                  </div>
                  <div className="action" onClick={() => null}>
                    <IconPencil size={12} />
                  </div>
                </div>
                <div
                  className={
                    "arrow" +
                    " " +
                    (selectedSuperCategoryReason &&
                    selectedSuperCategoryReason.id === superCat.id
                      ? "open"
                      : "")
                  }
                  onClick={() => {
                    if (
                      selectedSuperCategoryReason &&
                      selectedSuperCategoryReason.id === superCat.id
                    ) {
                      setSelectedSuperCategoryReason(null);
                    } else {
                      setSelectedSuperCategoryReason(superCat);
                    }
                  }}
                >
                  <IconChevronDown width={10} height="8" />
                </div>
              </div>
            </div>

            {selectedSuperCategoryReason &&
              selectedSuperCategoryReason.id === superCat.id && (
                <Fragment>
                  <div className="card card_large sub_card form_card">
                    <input
                      value={newCategoryReason ? newCategoryReason : ""}
                      placeholder={`add new category for ${superCat.label}...`}
                      onChange={(event) =>
                        setNewCategoryReason(event.target.value)
                      }
                    />
                    {newCategoryReason && (
                      <div className="action-side">
                        <div
                          className="arrow"
                          onClick={() => {
                            handleSaveReason(
                              "CATEGORY",
                              selectedSuperCategoryReason.id,
                              newCategoryReason
                            );
                          }}
                        >
                          <CheckIcon width={10} height="8" />
                        </div>
                      </div>
                    )}
                  </div>
                  {superCat.items &&
                    superCat.items.length &&
                    superCat.items.map((cat) => (
                      <Fragment>
                        <div
                          className={
                            "card card_large sub_card" +
                            " " +
                            (selectedCategoryReason &&
                            selectedCategoryReason.id === cat.id
                              ? "sub_card_open"
                              : "")
                          }
                        >
                          <div>{cat.label}</div>
                          <div className="action-side">
                            <div className="actions">
                              <div
                                className="action"
                                onClick={() =>
                                  setIsDeleteReasonModalOpen({
                                    type: "CATEGORY",
                                    id: cat.id,
                                  })
                                }
                              >
                                <IconTrash size={12} />
                              </div>
                              <div className="action" onClick={() => null}>
                                <IconPencil size={12} />
                              </div>
                            </div>
                            <div
                              className={
                                "arrow" +
                                " " +
                                (selectedSuperCategoryReason &&
                                selectedSuperCategoryReason.id === superCat.id
                                  ? "open"
                                  : "")
                              }
                              onClick={() => {
                                if (
                                  selectedCategoryReason &&
                                  selectedCategoryReason.id === cat.id
                                ) {
                                  setSelectedCategoryReason(null);
                                } else {
                                  setSelectedCategoryReason(cat);
                                }
                              }}
                            >
                              <IconChevronDown width={10} height="8" />
                            </div>
                          </div>
                        </div>
                        <div className="small-grid">
                          {selectedCategoryReason &&
                            selectedCategoryReason.id === cat.id && (
                              <Fragment>
                                <div
                                  className="card card_large sub_card form_card"
                                  style={{ marginLeft: 0 }}
                                >
                                  <input
                                    value={newReason ? newReason : ""}
                                    onChange={(event) =>
                                      setNewReason(event.target.value)
                                    }
                                    placeholder={`add new reason for ${cat.label}...`}
                                  />
                                  {newReason && (
                                    <div className="action-side">
                                      <div
                                        className="arrow"
                                        onClick={() => {
                                          handleSaveReason(
                                            "REASON",
                                            selectedCategoryReason.id,
                                            newReason
                                          );
                                        }}
                                      >
                                        <CheckIcon width={10} height="8" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {cat.items &&
                                  cat.items.length &&
                                  cat.items.map((reason) => (
                                    <div
                                      className={
                                        "card " +
                                        " " +
                                        (selectedReason &&
                                        selectedReason.id === reason.id
                                          ? "active"
                                          : "")
                                      }
                                      onClick={() => {
                                        setSelectedReason(reason);
                                      }}
                                    >
                                      <div>{reason.label}</div>
                                      <div className="action-side">
                                        <div className="actions">
                                          <div
                                            className="action"
                                            onClick={() =>
                                              setIsDeleteReasonModalOpen({
                                                type: "REASON",
                                                id: reason.id,
                                              })
                                            }
                                          >
                                            <IconTrash size={12} />
                                          </div>
                                          <div
                                            className="action"
                                            onClick={() => null}
                                          >
                                            <IconPencil size={12} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </Fragment>
                            )}
                        </div>
                      </Fragment>
                    ))}
                </Fragment>
              )}
          </Fragment>
        ))}
      </div>
      <div className="footer" style={{ width: "70%" }}>
        <div className="action_left" onClick={onCancel}>
          {_("cancel")}
        </div>
        <div
          className={
            "action_right" +
            " " +
            (selectedReason && selectedAnomaly ? "" : "disabled")
          }
          onClick={() => {
            if (!selectedReason || !selectedAnomaly) {
              return;
            } else {
              let newCard = {
                anomaly: selectedAnomaly,
                reason: selectedReason,
                plans: [],
              };
              onConfirm(newCard);
            }
          }}
        >
          {_("next")}
        </div>
      </div>
      <ModalConfirm
        type={"delete"}
        isOpen={isDeleteReasonModalOpen}
        onCancel={() => setIsDeleteReasonModalOpen(null)}
        onConfirm={() => {
          handleDeleteReason(isDeleteReasonModalOpen);
          setIsDeleteReasonModalOpen(null);
        }}
        inProcess={false}
        actionFailed={false}
        title={_("confirmDelete")}
        text={_(
          isDeleteReasonModalOpen && isDeleteReasonModalOpen.type === "REASON"
            ? "confirmReasonDeleteContent"
            : "confirmReasonCascadeDeleteContent"
        )}
        labelNo={_("cancel")}
        labelYes={_("delete")}
        labelError={"Error"}
      />
    </div>
  );
};

export default AddNewCorrectionCardFrame;
