import { useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import _ from "../i18n";

import { addLocale } from "primereact/api";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export const getDefaultLanguage = () => {
  let lng = navigator.language || navigator.userLanguage;
  lng = lng.split("-")[0];
  return ["fr", "en", "nl"].includes(lng) ? lng : "en";
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export const generateFullName = (firstName, lastName) => {
  firstName = firstName || "";
  lastName = lastName || "";
  const fullName = `${lastName} ${firstName}`.trim();
  return fullName !== "" ? fullName : _("unknown");
};

export const getUserNameForAvatar = (firstName, lastName) => {
  let fName = firstName ? firstName.split(" ") : "";
  if (fName.length >= 3) {
    return extractFirstLettre(fName, 3);
  } else {
    let lName = lastName ? lastName.split(" ") : "";
    return extractFirstLettre(fName.concat(lName), 3);
  }
};

function extractFirstLettre(arrayStr, length) {
  let result = "";
  for (let i = 0; i < length; i++) {
    if (arrayStr[i]) {
      result += arrayStr[i].substring(0, 1);
    }
  }
  return result.toUpperCase();
}

export const getMonthShortLabelObject = (lng) => {
  moment.locale(lng);
  let list = [];
  moment.monthsShort().forEach((element, index) => {
    list.push({
      id: index + 1,
      label: element,
      start: (index < 10 ? "0" : "") + (index + 1) + "-01",
      end: (index < 10 ? "0" : "") + (index + 1) + "-31",
    });
  });
  return list;
};

export const renderDurationDisplay = (item) => {
  let duration = Math.abs(parseFloat(item).toFixed(2));
  return Math.abs(duration) >= 60
    ? Math.floor(duration / 60) +
        "h " +
        ((duration / 60 - Math.floor(duration / 60)) * 60 > 0
          ? parseInt((duration / 60 - Math.floor(duration / 60)) * 60, 10) + "m"
          : "")
    : Math.floor(duration) +
        "m " +
        (duration - Math.floor(duration) > 0
          ? parseInt((duration - Math.floor(duration)) * 60, 10) + "s"
          : "");
};

export const setLocaleCalendarData = (lng) => {
  switch (lng) {
    case "fr":
      addLocale("fr", {
        firstDayOfWeek: 0,
        dayNames: [
          "lundi",
          "mardi",
          "mercredi",
          "jeudi",
          "vendredi",
          "samedi",
          "dimanche",
        ],
        dayNamesShort: ["lun", "mar", "mer", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["L", "M", "M", "J", "V", "S", "D"],
        monthNames: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        monthNamesShort: [
          "Janv",
          "Févr",
          "Mars",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Août",
          "Sept",
          "Oct",
          "Nov",
          "Déc",
        ],
        today: "Aujourd'hui",
        clear: "effacer",
      });

    case "nl":
      addLocale("nl", {
        firstDayOfWeek: 0,
        dayNames: [
          "maandag",
          "dinsdag",
          "woensdag",
          "donderdag",
          "vrijdag",
          "zaterdag",
          "zondag",
        ],
        dayNamesShort: ["maan", "din", "woe", "don", "vri", "zat", "zon"],
        dayNamesMin: ["M", "D", "W", "D", "V", "Z", "Z"],
        monthNames: [
          "januari",
          "februari",
          "maart",
          "april",
          "mei",
          "juni",
          "juli",
          "augustus",
          "september",
          "oktober",
          "november",
          "december",
        ],
        monthNamesShort: [
          "jan",
          "feb",
          "maa",
          "apr",
          "mei",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        today: "Vandaag",
        clear: "duidelijk",
      });

    case "en":
      addLocale("en", {
        firstDayOfWeek: 0,
        dayNames: [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ],
        dayNamesShort: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
        dayNamesMin: ["M", "T", "W", "T", "F", "S", "S"],
        monthNames: [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ],
        monthNamesShort: [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        today: "Today",
        clear: "Clear",
      });
    default:
      return;
  }
};

export const renderAvatar = (avatarUrl, name) => {
  let avatarDiv;
  if (!avatarUrl) {
    avatarDiv = (
      <div className="avatar_not_exist">
        <span>{getUserNameForAvatar(name)}</span>
      </div>
    );
  } else {
    avatarDiv = (
      <div
        className="avatar_exist"
        style={{
          backgroundImage: `url(${avatarUrl}`,
        }}
      />
    );
  }

  return avatarDiv;
};
export const extractNumber = (value) => {
  return parseInt(
    typeof value === "string" ? value.replace(/[^0-9.]/g, "") : value
  );
};
export const extractFloatNumber = (value) => {
  return parseFloat(
    typeof value === "string" ? value.replace(/[^0-9,.]/g, "") : value
  );
};

export const renderNotificationManagerMessage = (action, data) => {
  switch (action) {
    case "correction_card_request_validat":
      return (
        <div>
          <span>{data.user}</span> {`${_("requestCorrectionCardValidation")}`}{" "}
          <span>{data.data.title}</span>
        </div>
      );
    case "absence_request":
      return (
        <div>
          <span>{data.data.applicant}</span>{" "}
          {`${_("requestedAnAbscence")} ${_("from")}`}{" "}
          <span>{data.data.from}</span> {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "absence_approved":
      return (
        <div>
          <span>{data.user}</span> {_("approvedAbscenceOf")}{" "}
          <span>{data.data.applicant}</span> {_("from")}{" "}
          <span>{data.data.from}</span> {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "absence_affected":
      return (
        <div>
          <span>{data.user}</span> {_("affected")} {_("anAbscence")} {_("for")}
          <span>{data.data.beneficiary}</span> {_("from")}{" "}
          <span>{data.data.from}</span> {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "absence_rejected":
      return (
        <div>
          <span>{data.user}</span> {_("rejectededAbscenceOf")}{" "}
          <span>{data.data.applicant}</span> {_("from")}{" "}
          <span>{data.data.from}</span> {_("to")} <span>{data.data.to}</span>
        </div>
      );
    default:
      return;
  }
};

export const renderTeamYears = () => {
  let years = [];
  let year = 2020;

  while (year <= moment().year()) {
    years.push(year);
    year++;
  }
  return years;
};

export const renderNotificationCollaboratorMessage = (action, data) => {
  switch (action) {
    case "correction_card_validated":
      return (
        <div>
          <span>{data.user}</span> {`${_("validatedCorrectionCard")}`}{" "}
          <span>{data.data.title}</span>
        </div>
      );
    case "correction_card_returned":
      return (
        <div>
          <span>{data.user}</span> {`${_("returnedCorrectionCard")}`}{" "}
          <span>{data.data.title}</span> {`${_("from")}`}{" "}
          <span>{data.data.from}</span> {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "correction_card_removed":
      return (
        <div>
          <span>{data.user}</span> {`${_("hasRemovedYou")} ${_("from")}`}{" "}
          {_("correctionPlan")} <span>{data.data.title}</span>
        </div>
      );
    case "correction_card_affected":
      return (
        <div>
          <span>{data.user}</span> {`${_("hasAffectedYou")} ${_("to")}`}{" "}
          {_("correctionPlan")} <span>{data.data.title}</span>
        </div>
      );
    case "absence_affected":
      return (
        <div>
          <span>{data.user}</span> {_("affectedYou")} {_("anAbscence")}{" "}
          {_("from")} <span>{data.data.from}</span> {_("to")}{" "}
          <span>{data.data.to}</span>
        </div>
      );
    case "absence_request":
      return (
        <div>
          <span>{_("you")}</span>{" "}
          {`${_("youRequestedAnAbscence")} ${_("from")}`}{" "}
          <span>{data.data.from}</span> {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "absence_approved":
      return (
        <div>
          <span>{data.user}</span> {_("approvedAbscenceOf")}{" "}
          {_("yourAbscenceRequest")} {_("from")} <span>{data.data.from}</span>{" "}
          {_("to")} <span>{data.data.to}</span>
        </div>
      );
    case "absence_rejected":
      return (
        <div>
          <span>{data.user}</span> {_("rejectededAbscenceOf")}{" "}
          {_("yourAbscenceRequest")} {_("from")} <span>{data.data.from}</span>{" "}
          {_("to")} <span>{data.data.to}</span>
        </div>
      );
    default:
      return;
  }
};
