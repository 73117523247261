import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getManagerNotification } from "../../api";
import { setManagerNotification } from "../../store";
import { CircleIcon, IconPlus } from "../common/Icons";
import { NOTIFICATION_TABS } from "../../config";
import {
  renderNotificationManagerMessage,
  renderNotificationCollaboratorMessage,
} from "../../utils";
import moment from "moment";
import _ from "../../i18n";
import LigthPageHeader from "../common/PageHeader/LigthPageHeader";

import "./NotificationPage.local.scss";

const NotificationPage = () => {
  const { language } = useSelector((state) => state.params);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { managerNotification, loggedAs } = auth;
  const [interval, setInterval] = useState({ start: 10, limit: 10 });
  const [activeTab, setActiveTab] = useState({
    tab: "allNotifications",
  });

  const preferences = auth.user
    ? auth.user.userOrganizations.find(
        (organization) => organization.id === auth.navCommunity.id
      ).authorisation.preferences
    : {};

  const handleFetchNotifications = async () => {
    try {
      let managerNotificationQuery = getManagerNotification(
        auth.user.uid,
        auth.navCommunity.id,
        auth.token,
        auth.loggedAs === "COLLABORATOR"
          ? ["correction_card_validated"]
          : [
              "correction_card_affected",
              "correction_card_removed",
              "correction_card_returned",
              "correction_card_validated",
            ],
        interval.start,
        interval.limit
      );
      if (managerNotificationQuery) {
        managerNotificationQuery.then((result) => {
          const data = [...managerNotification.data, ...result.data.data.data];
          dispatch(
            setManagerNotification({
              nbrResult: managerNotification.nbrResult,
              data,
            })
          );
          setInterval({
            ...interval,
            start: interval.start + 10,
          });
        });
      }
    } catch (e) {
      return null;
    }
  };

  const renderTabContent = () => {
    return NOTIFICATION_TABS.map((tab) => (
      <div
        className={`${activeTab.tab === tab.tab ? "tab_active" : ""} tab`}
        onClick={() => setActiveTab(tab)}
      >
        {tab.icon}
        <div>{_(tab.tab)}</div>
      </div>
    ));
  };
  const renderListNotifications = () => {
    return (
      (loggedAs === "COLLABORATOR" ||
        (preferences &&
          preferences.activateNotifications &&
          loggedAs === "MANAGER")) && (
        <div className={"notifications_list"} style={{ right: "36px" }}>
          {managerNotification && managerNotification.data.length > 0 ? (
            <div className={"list"}>
              {managerNotification &&
              managerNotification.data.filter(
                (notific) =>
                  activeTab.tab === "allNotifications" ||
                  activeTab.actions.includes(notific.data.action)
              ).length > 0 ? (
                managerNotification &&
                managerNotification.data
                  .filter(
                    (notific) =>
                      activeTab.tab === "allNotifications" ||
                      activeTab.actions.includes(notific.data.action)
                  )
                  .map((notif) => (
                    <Link
                      to={NOTIFICATION_TABS.filter(
                        (tab) =>
                          tab.actions && tab.actions.includes(notif.data.action)
                      )[0]
                        .redirect.replace(":language", language)
                        .replace(":id", notif.collaborator)}
                    >
                      <div className="notif_frame">
                        <div className="notif_frame_header">
                          <div>{_(notif.data.action)}</div>
                          <div>
                            {moment(notif.createdAt.date).format("DD-MM-Y") ===
                            moment().format("DD-MM-Y")
                              ? moment(notif.createdAt.date)
                                  .locale(language)
                                  .fromNow()
                              : moment(notif.createdAt.date).format("DD-MM-Y")}
                          </div>
                        </div>
                        <div className="notif_frame_body">
                          {loggedAs === "COLLABORATOR"
                            ? renderNotificationCollaboratorMessage(
                                notif.data.action,
                                notif.data
                              )
                            : renderNotificationManagerMessage(
                                notif.data.action,
                                notif.data
                              )}
                          <CircleIcon
                            fill={notif.status === "PENDING" ? "#FF636E" : ""}
                          />
                        </div>
                      </div>
                    </Link>
                  ))
              ) : (
                <div className="no_data_notif" style={{ width: 350 }}>
                  <div className="title">{_("noNotificationsFound")}</div>
                </div>
              )}
              {managerNotification &&
                managerNotification.nbrResult >
                  managerNotification.data.length && (
                  <div
                    className="notif_frame notif_action"
                    onClick={() => handleFetchNotifications()}
                  >
                    <div>
                      <IconPlus />
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div className="no_data_notif">
              <div className="title">{_("noNotificationsFound")}</div>
            </div>
          )}
        </div>
      )
    );
  };

  return (
    <div className="page-content">
      <LigthPageHeader
        title={{
          label: "notifications",
        }}
      />
      <div className="content-notification">
        <div className="content-aside">{renderTabContent()}</div>

        <div className="content-frame">{renderListNotifications()}</div>
      </div>
    </div>
  );
};

export default NotificationPage;
