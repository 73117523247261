import React, { useState } from "react";
import { useQuery } from "react-query";

import _ from "../../../i18n";
import { PRIORITY_LEVELS, CARD_TYPE } from "../../../config";
import {
  getZonesSuggestions,
  getCorrectionCardAnomalies,
  getOrganizationCollaborators,
  getClientsSuggestions,
  getCorrectionCardReasons,
} from "../../../api";

import TTPGroupFilterHorizontalBar from "../../common/Filter/TTPGroupFilterHorizontalBar";

const CollaboratorPlansBoardFilter = ({
  id,
  year,
  filter,
  updateFilter,
  filterVisible,
  token,
  updateFiltersCount,
  organization,
}) => {
  const [anomalies, setAnomalies] = useState([]);
  const [superReasons, setSuperReasons] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);

  let correctionCardAnomaliesQuery = useQuery(
    ["correctionCardAnomaliesQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCorrectionCardAnomalies(
            organization,
            token
          );
          let data = response.data.data.map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
          setAnomalies(data);
        } catch (e) {
          setAnomalies([]);
          return null;
        }
      }
    }
  );

  let correctionCardReasonsQuery = useQuery(
    ["correctionCardReasonsQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCorrectionCardReasons(organization, token);
          let data = response.data.data.map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
          setSuperReasons(data);
        } catch (e) {
          setSuperReasons([]);
          return null;
        }
      }
    }
  );

  let clientsdQuery = useQuery(
    ["getClients", token, organization],
    async () => {
      if (token && organization) {
        try {
          const response = await getClientsSuggestions(
            token,
            [],
            organization,
            "isEntity",
            year
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setClientOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let zonesQuery = useQuery(["zonesQuery", organization], async () => {
    if (token) {
      try {
        const response = await getZonesSuggestions(token, organization);
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setZoneOptions(data);
      } catch (e) {
        setZoneOptions([]);
        return null;
      }
    }
  });

  let collaboratorsdQuery = useQuery(
    ["collaboratorsQuery", organization, filter],
    async () => {
      if (token) {
        try {
          const response = await getOrganizationCollaborators(
            organization,
            year,
            token,
            filter.zone ? [filter.zone.value] : [],
            ""
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          setCollaboratorOptions([]);
          return null;
        }
      }
    }
  );

  let filters = [
    {
      type: "SELECT",
      group: "cor_card",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientFolder"),
        options: clientOptions,
        values: filter.client,
        action: (value) => {
          if (value.length == 0) {
            let cleanFilter = { ...filter };
            delete cleanFilter.client;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, client: value });
          }
        },
      },
    },
    {
      type: "SELECT",
      group: "commun",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("priorityLevel"),
        options: PRIORITY_LEVELS,
        values: filter.priority,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.priority;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, priority: value });
          }
        },
      },
    },
    {
      type: "SELECT",
      group: "cor_card",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("anomaly"),
        options: anomalies,
        values: parseInt(filter.anomaly),
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.anomaly;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, anomaly: value });
          }
        },
      },
    },
    {
      type: "SELECT",
      group: "commun",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone ? filter.zone.value : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.zone;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              collaborators: [],
              responsables: [],
              zone: zoneOptions.filter((option) => option.value == value)[0],
            });
          }
        },
      },
    },
    {
      type: "SELECT",
      group: "cor_card",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("reason"),
        options: superReasons,
        values: filter.reasons,
        action: (value) => {
          if (value.length == 0) {
            let cleanFilter = { ...filter };
            delete cleanFilter.reasons;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, reasons: value });
          }
        },
      },
    },
    {
      type: "SELECT",
      group: "commun",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("cardType"),
        options: CARD_TYPE,
        values: filter.cardType,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.cardType;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, cardType: value });
          }
        },
      },
    },
  ];

  if (!id) {
    filters.push({
      type: "SELECT",
      group: "commun",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("responsables"),
        options: collaboratorOptions,
        values: filter.responsables,
        action: (value) => {
          if (value.length == 0) {
            let cleanFilter = { ...filter, responsables: [] };
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, responsables: value });
          }
        },
      },
    });
    filters.push({
      type: "SELECT",
      group: "dev_card",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: (value) => {
          if (value.length == 0) {
            let cleanFilter = { ...filter, collaborators: [] };
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, collaborators: value });
          }
        },
      },
    });
  }

  let countFilter = 0;
  filters.forEach((filter) => {
    if (
      filter &&
      filter.options.values &&
      (filter.options.values > 0 || filter.options.values.length > 0)
    ) {
      countFilter++;
    }
  });
  updateFiltersCount(countFilter);

  let groups = [];
  if (!filter.cardType) {
    groups = ["commun", "cor_card", "dev_card"];
  } else {
    groups = ["commun", filter.cardType];
  }

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPGroupFilterHorizontalBar
          bg={id ? "#F8F9FA" : "#FFF"}
          subBG={id ? "FFF" : "F8F9FA"}
          filter={filter}
          filters={filters}
          groups={groups}
          countFilter={filter.countFilter}
          updateFilterContent={updateFilter}
          filterFormIsVisible={filterVisible}
          cssClass="medium-12"
          fetching={
            clientsdQuery.isFetching ||
            collaboratorsdQuery.isFetching ||
            correctionCardAnomaliesQuery.isFetching ||
            correctionCardReasonsQuery.isFetching
          }
        />
      </div>
    </div>
  );
};

export default CollaboratorPlansBoardFilter;
