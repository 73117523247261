import axios from "axios";
import moment from "moment";

import { TTP_API_URL } from "../config";

export const getClientDetails = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/client/details/${id}`;
  //TODO

  let params = {
    access_token: token,
    organization_id: organization,
    organizationId: organization,
    year: data.year,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getClientProgressionBudget = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/budget/client/progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organization,
      client: id,
      year: data.year,
      category: data.category.id
    },
  });
};

export const getClientActivities = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/client/activities`;

  let params = {
    access_token: token,
    organization_id: organization,
    organizationId: organization,
    year: data.year,
    client: id,
    lng: data.language,
    after: moment().subtract(7, "d").format("YYYY-MM-DD"),
  };

  return axios.get(requestUrl, {
    params,
  });
};
