import React from "react";
import {
  CalendarIcon,
  CheckCircleIcon,
  FolderIcon,
  RepeatIcon,
  ClockIcon,
  FlagIcon,
} from "../common/Icons";
import "./Reporting.local.scss";

const Item = ({ lng, item, key, index, year }) => {
  return (
    <div
      key={key}
      className={index === 0 ? "reporting_item items_header" : "reporting_item"}
    >
      <div className="reporting_item_infos">
        <div className="reporting_item_infos_header">{item.name}</div>
        <div className="reporting_item_infos_sub_header">{item.role} </div>
      </div>
      <div className="reporting_item_state">
        {index !== 0 && <FlagIcon />}
        <div
          className={
            index === 0
              ? "label_header reporting_item_state_label"
              : "reporting_item_state_label"
          }
        >
          {item.carte_de_correction}
        </div>
      </div>
      <div className="reporting_item_state">
        {index !== 0 && <FolderIcon />}
        <div
          className={
            index === 0
              ? "label_header reporting_item_state_label"
              : "reporting_item_state_label"
          }
        >
          {item.dossier_avec_anomalie}
        </div>
      </div>
      <div className="reporting_item_state">
        {index !== 0 && <CalendarIcon />}
        <div
          className={
            index === 0
              ? "label_header reporting_item_state_label"
              : "reporting_item_state_label"
          }
        >
          {item.Temps_moyen}
        </div>
      </div>
      <div className="reporting_item_state">
        {index !== 0 && <ClockIcon fill={"#6D7F92"} />}
        <div
          className={
            index === 0
              ? "label_header reporting_item_state_label"
              : "reporting_item_state_label"
          }
        >
          {item.respect_des_deadlines}
        </div>
      </div>
      <div className="reporting_item_state">
        {index !== 0 && <RepeatIcon />}
        <div
          className={
            index === 0
              ? "label_header reporting_item_state_label"
              : "reporting_item_state_label"
          }
        >
          {item.taux_de_recidive}
        </div>
      </div>
      <div
        className="reporting_item_state"
        // style={{ borderRight: "none !important" }}
      >
        {index !== 0 && <CheckCircleIcon />}
        <div
          className={
            index === 0
              ? "label_header reporting_item_state_label"
              : "reporting_item_state_label"
          }
        >
          {item.taux_de_validation}
        </div>
      </div>
    </div>
  );
};

export default Item;
