import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";

import _ from "../../../i18n";
import SearchBox from "./SearchBox";
import PageSize from "./PageSize";
import { FilterIcon, IconClose } from "../Icons";
import "./TTPFilterHorizontalBar.local.scss";

const TTPGroupFilterHorizontalBar = ({
  bg,
  subBG,
  groups,
  button,
  filter,
  filters,
  fetching,
  nbResult,
  cssClass,
  pageSize,
  searchBox,
  countFilter,
  customAddOn,
  searchPlaceholder,
  filterFormIsVisible,
  updateFilterContent,
}) => {
  const [filterIsVisible, setFilterIsVisible] = useState(filterFormIsVisible);

  const handleToggleFilterForm = () => {
    const isVisible = !filterIsVisible;
    setFilterIsVisible(isVisible);
    if (filterFormIsVisible) {
      setFilterIsVisible(isVisible);
    }
  };

  useEffect(() => {
    setFilterIsVisible(filterFormIsVisible);
  }, [filterFormIsVisible]);

  const onInputChange = (inputValue, action, isSimple = false) => {
    let selected = null;
    if (isSimple) {
      selected = inputValue ? inputValue.value + "" : null;
    } else {
      selected = inputValue.map(({ value }) => value);
    }
    action(selected);
  };

  const renderFilters = () => {
    let filterCols = [];

    if (groups.length && groups.length && filters && filters.length > 0) {
      filterCols = groups.map((group, index1) => {
        let goupFilter = filters.filter((element) => element.group === group);
        if (goupFilter.length === 0) {
          return;
        }
        return (
          <div className="ttp_filter_group">
            <div className="group_label">
              <label>{_(group)}</label>
            </div>
            {filters
              .filter((element) => element.group === group)
              .map((filter, index2) => {
                return (
                  <div
                    className="element-frame"
                    key={`col-${index1}-${index2}`}
                  >
                    {renderFilterByType(filter.type, filter.options)}
                  </div>
                );
              })}
          </div>
        );
      });
    }

    return filterCols;
  };

  const renderFilterByType = (type, options) => {
    switch (type) {
      case "SELECT": {
        // TODO refactor to Config constante
        return renderSimpleSelect(options);
      }
      default:
        return <div />;
    }
  };

  const renderSimpleSelect = (options) => {
    return (
      <div key={options.placeholder} className={`columns`}>
        <label key={options.placeholder}>
          <span className="lbl">{_(options.placeholder)}</span>
          <Select
            isMulti={options.isMulti !== undefined ? options.isMulti : false}
            isSearchable={false}
            isClearable={
              options.isClearable !== undefined ? options.isClearable : false
            }
            closeMenuOnSelect
            placeholder={options.placeholder}
            options={options.options}
            value={filterValues(options.options, options.values)}
            onChange={(inputValue) =>
              onInputChange(inputValue, options.action, options.isSimple)
            }
            className={`ttp-select select_filter uppercase ${
              options.values && options.values.length > 0
                ? "filled-box"
                : "empty-box"
            } ${subBG ? `bg_${subBG}` : ""}`}
            classNamePrefix="ttp-select"
            formatGroupLabel={formatGroupLabel}
            styles={options[0] && options[0].color && colourStyles}
          />
        </label>
      </div>
    );
  };

  return (
    <div
      id="ttp-horizontal-filter"
      className={`${
        filterIsVisible && "ttp-horizontal-filter__open"
      } ${cssClass}`}
    >
      <div className="filter-wrapper">
        <div className="filter-wrapper">{customAddOn}</div>
        <div className="filter-wrapper">
          {searchBox && (
            <SearchBox
              placeholder={searchPlaceholder}
              onChange={(item) =>
                updateFilterContent({ ...filter, keyWord: item })
              }
              filter={filter}
              nbResult={nbResult}
              fetching={fetching}
            />
          )}
          {pageSize && (
            <PageSize
              pageSize={pageSize}
              onPageSizeChange={(item) =>
                updateFilterContent({ ...filter, pageSize: item })
              }
            />
          )}
          {button && (
            <button
              className="filter-button m-l-s"
              onClick={() => handleToggleFilterForm()}
            >
              {_("filter")}
              {countFilter > 0 ? (
                <span>{countFilter}</span>
              ) : (
                <FilterIcon className="filter-icon" />
              )}
            </button>
          )}
        </div>
      </div>
      <CSSTransition
        in={filterIsVisible}
        timeout={200}
        classNames="filter-form"
        unmountOnExit
      >
        <div
          className="row filter-form"
          style={{ background: bg ? bg : "#fff" }}
        >
          {renderFilters()}
          <div
            className="filter-close"
            onClick={() => handleToggleFilterForm()}
          >
            <IconClose size={12} />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export const filterValues = (options, values) => {
  if (options && options.length > 0 && options[0].options) {
    options = options.reduce((acc, o) => {
      return acc.concat(o.options);
    }, []);
  }
  let result = options.filter(({ value }) => {
    return (
      values &&
      (Array.isArray(values) ? values.includes(value) : value === values)
    );
  });
  return result;
};

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color + "22"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? true
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  multiValue: (styles, { data }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: color + "22",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export default TTPGroupFilterHorizontalBar;
