import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import _ from "../../../i18n";

import PageHeader from "../../common/PageHeader/PageHeader";
import { TrelloIcon } from "../../common/Icons";
import { useOnClickOutside, renderTeamYears } from "../../../utils";
import { TiArrowSortedDown } from "react-icons/ti";
import CollaboratorPlansBoard from "../../CollaboratorSpace/PlansBoard/CollaboratorPlansBoard";

const GeneralPlanBoards = () => {
  const refYears = useRef();
  useOnClickOutside(refYears, () => setYearDivIsVisible(false));
  const { language } = useSelector((state) => state.params);
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
  });

  const renderHeaderAction = () => {
    return (
      <div
        ref={refYears}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    setFilter({ ...filter, year: item });
                    setYearDivIsVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="page-content">
      <PageHeader
        header={_("plansBoard")}
        subHeader={_("subTitlePlansBoard")}
        icon={<TrelloIcon size="36" fill="#29394D" />}
      >
        {renderHeaderAction()}
      </PageHeader>
      <div className="content-body content-body_global">
        <CollaboratorPlansBoard
          token={token}
          lng={language}
          year={filter.year}
          loggedAs={loggedAs}
          organization={navCommunity && navCommunity.id}
          user={user ? user.firstName + " " + user.lastName : ""}
        />
      </div>
    </div>
  );
};

export default GeneralPlanBoards;
