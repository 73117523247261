import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth";
import paramsSlice from "./params";
import userSlice from "./user";
import clientBudgetSlice from "./budget";
import modelesSlice from "./modeles";
import collaboratorSlice from "./collaborator";
import squadsSlice from "./squads";
import buSlice from "./bu";
import clustersSlice from "./clusters";

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    params: paramsSlice,
    users: userSlice,
    clientBudget: clientBudgetSlice,
    modeles: modelesSlice,
    collaborators: collaboratorSlice,
    squads: squadsSlice,
    bu: buSlice,
    clusters: clustersSlice,
  }),
});
