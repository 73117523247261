import React from "react";

export const MaximizeIcon = ({ size = 13, fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6667 2.50033C11.6667 2.04009 12.0398 1.66699 12.5001 1.66699H17.5001C17.9603 1.66699 18.3334 2.04009 18.3334 2.50033V7.50032C18.3334 7.96056 17.9603 8.33366 17.5001 8.33366C17.0398 8.33366 16.6667 7.96056 16.6667 7.50032V3.33366H12.5001C12.0398 3.33366 11.6667 2.96056 11.6667 2.50033Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.50008 11.667C2.96032 11.667 3.33341 12.0401 3.33341 12.5003V16.667H7.50008C7.96032 16.667 8.33341 17.0401 8.33341 17.5003C8.33341 17.9606 7.96032 18.3337 7.50008 18.3337H2.50008C2.03984 18.3337 1.66675 17.9606 1.66675 17.5003V12.5003C1.66675 12.0401 2.03984 11.667 2.50008 11.667Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0892 1.91107C18.4146 2.23651 18.4146 2.76414 18.0892 3.08958L12.2558 8.92291C11.9304 9.24835 11.4028 9.24835 11.0773 8.92291C10.7519 8.59748 10.7519 8.06984 11.0773 7.7444L16.9107 1.91107C17.2361 1.58563 17.7637 1.58563 18.0892 1.91107Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.92267 11.0771C9.24811 11.4025 9.24811 11.9302 8.92267 12.2556L3.08934 18.0889C2.7639 18.4144 2.23626 18.4144 1.91083 18.0889C1.58539 17.7635 1.58539 17.2359 1.91083 16.9104L7.74416 11.0771C8.0696 10.7516 8.59723 10.7516 8.92267 11.0771Z"
      fill={fill}
    />
  </svg>
);
