import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import _ from "../../i18n";

import { getUserNameForAvatar, generateFullName } from "../../utils";

import {
  CheckIcon,
  AlertIcon,
  BookMarkIcon,
  PullIcon,
  FlagIcon,
  SettingsIcon,
  ClipBoardIcon,
} from "../common/Icons";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Tooltip } from "antd";

import "./Item.local.scss";

const Item = ({
  lng,
  item,
  key,
  responsables,
  preferences,
  year,
  isEntity,
  setCorrectionCardClient,
  setActiveAttachedClient,
  activeAttachedClient,
  loggedAs,
  spaceAs,
}) => {
  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  return (
    <div
      key={key}
      className={`item ${
        activeAttachedClient === item.id ? "item_opened" : ""
      }`}
      style={{
        marginLeft: !isEntity && "83px",
        height: !isEntity && "100px",
        opacity: item.isDeleted && "0.5"
      }}
    >
      <div className="item_infos">
        <div className="item_infos_header">{item.name}</div>
        <div className="item_infos_sub_header">
          {item.uen ? `${item.uen} - ` : ""} {item.group}{" "}
        </div>
        <div className="item_infos_sub_header">
          {_("ID")} : {item.erp}
        </div>
      </div>
      {isEntity && (
        <Tooltip
          key={`entity-${key}`}
          placement="top"
          title={
            <div>
              <label>
                {_(item.isEntityClient ? "hisAssociates" : "attachedTo")}
              </label>
              <ul>
                {item.isEntityClient
                  ? item.attached.length > 0
                    ? item.attached.map((item) => <li>{item.name}</li>)
                    : _("none")
                  : item.entityClient.name}
              </ul>
            </div>
          }
        >
          <div className="item_state">
            {item.isEntityClient ? <PullIcon /> : <BookMarkIcon />}
            <div className="item_state_label">
              {_(item.isEntityClient ? "entity" : "attatched")}
            </div>
          </div>
        </Tooltip>
      )}
      <div
        className={`item_progress ${
          item.budget.hours.real > item.budget.hours.budget
            ? "bg-danger"
            : "bg-success"
        }`}
      >
        <div className="item_progress_info">
          <div>{_("hourBudget")}</div>
          <div>{`${item.budget.hours.budget}h`}</div>
        </div>
        <div
          style={{
            height: "38px",
            display: "flex",
            marginTop: 5,
            backgroundColor: "transparent",
          }}
        >
          <div className="item_progress_container">
            <div
              className="item_progress_bar"
              style={{
                width:
                  parseInt(item.budget.hours.real) >
                  parseInt(item.budget.hours.budget)
                    ? "100%"
                    : parseInt(item.budget.hours.budget) > 0 &&
                      parseInt(item.budget.hours.real) > 0
                    ? `${
                        (parseInt(item.budget.hours.real) * 100) /
                        parseInt(item.budget.hours.budget)
                      }%`
                    : 0,

                background:
                  parseFloat(item.budget.hours.real) >
                  parseFloat(item.budget.hours.budget)
                    ? "#EB5757"
                    : "#06D9B1",
              }}
            />
            <div
              className="item_progress_index"
              style={{
                width:
                  parseInt(item.budget.hours.real) >
                  parseInt(item.budget.hours.budget)
                    ? "200%"
                    : parseInt(item.budget.hours.budget) > 0 &&
                      parseInt(item.budget.hours.real) > 0
                    ? `${
                        (parseInt(item.budget.hours.real) * 200) /
                        parseInt(item.budget.hours.budget)
                      }%`
                    : 0,
                color:
                  parseFloat(item.budget.hours.real) >
                  parseFloat(item.budget.hours.budget)
                    ? "#DE4848"
                    : "#02AF8E",
              }}
            >
              <TiArrowSortedDown />{" "}
              <div>
                {parseFloat(item.budget.hours.real) > 0
                  ? `${parseFloat(
                      parseFloat(item.budget.hours.real).toFixed(2)
                    )}h`
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`item_progress ${
          parseFloat(item.budget.hours.real) > 0 &&
          parseFloat(item.budget.economic.real) /
            parseFloat(item.budget.hours.real) >
            80
            ? "bg-success"
            : "bg-warning"
        }`}
      >
        <div className="item_progress_info">
          <div>{_("economicBudget")}</div>
          <div>
            {parseInt(item.budget.economic.budget) > 0
              ? `${item.budget.economic.budget}€`
              : 0}
          </div>
        </div>
        <div
          style={{
            height: "38px",
            display: "flex",
            marginTop: 5,
            backgroundColor: "transparent",
          }}
        >
          <div className="item_progress_container">
            <div
              className="item_progress_bar"
              style={{
                width:
                  parseInt(item.budget.economic.real) >
                  parseInt(item.budget.economic.budget)
                    ? "100%"
                    : parseInt(item.budget.economic.budget) > 0 &&
                      parseInt(item.budget.economic.real) > 0
                    ? `${
                        (parseInt(item.budget.economic.real) * 100) /
                        parseInt(item.budget.economic.budget)
                      }%`
                    : 0,
                background:
                  parseFloat(item.budget.hours.real) > 0 &&
                  parseFloat(item.budget.economic.real) /
                    parseFloat(item.budget.hours.real) >
                    80
                    ? "#06D9B1"
                    : "#EA8124",
              }}
            />
            <div
              className="item_progress_index"
              style={{
                width:
                  parseFloat(item.budget.economic.budget) > 0 &&
                  parseFloat(item.budget.economic.real) > 0
                    ? parseFloat(item.budget.economic.real) >
                      parseFloat(item.budget.economic.budget)
                      ? `inherit`
                      : `${
                          (parseFloat(item.budget.economic.real) * 200) /
                          parseFloat(item.budget.economic.budget)
                        }%`
                    : "fit-content",
                color:
                  parseFloat(item.budget.hours.real) > 0 &&
                  parseFloat(item.budget.economic.real) /
                    parseFloat(item.budget.hours.real) >
                    80
                    ? "#02AF8E"
                    : "#EA8124",
                alignItems:
                  parseFloat(item.budget.economic.real) >
                  parseFloat(item.budget.economic.budget)
                    ? "end"
                    : "center",
              }}
            >
              <TiArrowSortedDown />{" "}
              <div>
                {parseFloat(item.budget.economic.real) > 0
                  ? `${parseFloat(
                      parseFloat(item.budget.economic.real).toFixed(2)
                    )}€`
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEntity && (
        <div className="item_tags">
          {item.budgetState === "BUDGET_COMPLETED" ? (
            <div className="tag tag-success">
              <CheckIcon fill="#02AF8E" /> {_("completedBudgetization")}
            </div>
          ) : (
            <div class="pie-frame">
              <div
                class="pie"
                style={{
                  "--p": 100,
                  "--c": "rgba(109, 127, 146, 0.3)",
                  position: "relative",
                  top: 50,
                }}
              />
              <div
                class="pie"
                style={{
                  "--p": item.pourcentage,
                }}
              >
                {`${item.pourcentage}%`}
              </div>
              <div className="tag tag-danger">
                {item.budgetState === "UNAUTHORIZED_BUDGET" && <AlertIcon />}
                {_(item.budgetState)}
              </div>
            </div>
          )}
        </div>
      )}
      {isEntity && (
        <div className="item_avatars">
          {responsables.map((item, index) => (
            <Tooltip
              key={`role-${index}-${item.role}`}
              placement="top"
              title={_(item.role)}
            >
              <div className="avatar" key={index}>
                {renderAvatar(item.avatar, item.firstName, item.lastName)}
              </div>
            </Tooltip>
          ))}
        </div>
      )}
      {isEntity && (
        <div className="item_actions">
          {item.attachedList.length > 0 && (
            <Tooltip
              key="tool-1"
              placement="top"
              title={_("Visualiser les client attachés")}
              mouseLeaveDelay={0}
            >
              <div
                className="action"
                onClick={() =>
                  setActiveAttachedClient(
                    activeAttachedClient !== item.id ? item.id : 0
                  )
                }
              >
                {activeAttachedClient !== item.id ? (
                  <TiArrowSortedDown />
                ) : (
                  <TiArrowSortedUp />
                )}
              </div>
            </Tooltip>
          )}
          {(loggedAs === "MANGER" || spaceAs === "MANAGER") &&
            preferences &&
            preferences.allowConfigurateBudget === "1" && (
              <Fragment>
                <Tooltip
                  key="tool-1"
                  placement="top"
                  title={_("manageBudget")}
                  mouseLeaveDelay={0}
                >
                  <Link to={`/${lng}/budget/${item.id}/${year}`}>
                    <div className="action">
                      <SettingsIcon />
                    </div>
                  </Link>
                </Tooltip>
              </Fragment>
            )}
          {preferences && preferences.allowCreateCorrectionCards === "1" && (
            <Tooltip
              key="tool-1"
              placement="top"
              title={_("cor_card")}
              mouseLeaveDelay={0}
            >
              <div
                className="action"
                onClick={() =>
                  setCorrectionCardClient({
                    ...item,
                    responsables: responsables
                      .filter((element) =>
                        ["MANAGER", "GE"].includes(element.role)
                      )
                      .map((responsable) => {
                        return {
                          uid: responsable.uid,
                          name:
                            responsable.firstName + " " + responsable.lastName,
                        };
                      }),
                  })
                }
              >
                <FlagIcon size={"15"} fill={"#18a0fb"} />
              </div>
            </Tooltip>
          )}
          {preferences && preferences.allowAccessClientSpace === "1" && (
            <Tooltip
              key="tool-2"
              placement="top"
              title={_("clientForm")}
              mouseLeaveDelay={0}
            >
              <Link to={`/${lng}/fiche-client/${item.id}`}>
                <div className="action">
                  <ClipBoardIcon />
                </div>
              </Link>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default Item;
