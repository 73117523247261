import React from "react";

export const AlertIcon = ({ fill = "#EB5757" }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_58930_26724)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.01497 1.64214C6.23961 1.51566 6.49306 1.44922 6.75086 1.44922C7.00867 1.44922 7.26212 1.51566 7.48676 1.64214C7.71141 1.76862 7.89966 1.95086 8.03336 2.17128L8.03481 2.17365L12.2698 9.24366L12.2739 9.25056C12.4048 9.47738 12.4741 9.73454 12.4749 9.99645C12.4756 10.2584 12.4077 10.5159 12.278 10.7435C12.1483 10.971 11.9613 11.1606 11.7356 11.2934C11.5098 11.4263 11.2533 11.4977 10.9914 11.5006L10.9859 11.5006L2.51037 11.5006C2.24847 11.4977 1.99189 11.4263 1.76615 11.2934C1.54041 11.1606 1.35339 10.971 1.22369 10.7435C1.09399 10.5159 1.02613 10.2584 1.02686 9.99645C1.02759 9.73454 1.0969 9.47738 1.22787 9.25056L1.23193 9.24366L5.46836 2.17127C5.60206 1.95085 5.79032 1.76862 6.01497 1.64214ZM6.75086 2.44922C6.66493 2.44922 6.58045 2.47137 6.50557 2.51353C6.43102 2.55549 6.3685 2.61588 6.32397 2.68891L2.09221 9.7535C2.04962 9.8284 2.0271 9.91305 2.02686 9.99925C2.02661 10.0866 2.04923 10.1724 2.09247 10.2483C2.1357 10.3241 2.19804 10.3873 2.27329 10.4316C2.34787 10.4755 2.43256 10.4992 2.51906 10.5006H10.9827C11.0692 10.4992 11.1539 10.4755 11.2284 10.4316C11.3037 10.3873 11.366 10.3241 11.4093 10.2483C11.4525 10.1724 11.4751 10.0866 11.4749 9.99925C11.4746 9.91306 11.4521 9.82842 11.4095 9.75353L7.17837 2.6899C7.17816 2.68957 7.17796 2.68924 7.17776 2.68891C7.13323 2.61588 7.07071 2.55549 6.99616 2.51353C6.92128 2.47137 6.8368 2.44922 6.75086 2.44922Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75098 4.5C7.02712 4.5 7.25098 4.72386 7.25098 5V7C7.25098 7.27614 7.02712 7.5 6.75098 7.5C6.47483 7.5 6.25098 7.27614 6.25098 7V5C6.25098 4.72386 6.47483 4.5 6.75098 4.5Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.25098 9C6.25098 8.72386 6.47483 8.5 6.75098 8.5H6.75598C7.03212 8.5 7.25598 8.72386 7.25598 9C7.25598 9.27614 7.03212 9.5 6.75598 9.5H6.75098C6.47483 9.5 6.25098 9.27614 6.25098 9Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_58930_26724">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.750977 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
